<template>
  <component :is="optionComponent" />
</template>

<script>
import Park from "../models/Park.js";

export default {
  data() {
    return {
      validRoute: true,
      availableRoutes: null,
    };
  },
  mounted() {
    if (this.$route.name === "Home") {
      this.validRoute = true;
      this.$store.commit("setRouteId", "entrada");
    } else if (this.$route.name === "HomeId") {
      let park = this.$store.state.parkData;

      var typeR = this.$store.state.typeRoute;
      if(typeR == 2){
        this.availableRoutes = park.routesShort.map((route) => route.id);
      }else{
        this.availableRoutes = park.routes.map((route) => route.id);
      }

      if (this.availableRoutes.includes(this.$route.params.id)) {
        this.$store.commit("setRouteId", this.$route.params.id);
        this.validRoute = true;
      } else {
        this.$store.commit("setRouteId", null);
        this.validRoute = false;
      }
    }
    this.setupCurrentRoute(this.$store.state.routeId);
  },
  computed: {
    optionComponent() {
      if (this.$route.name === "Home") {
        return () => import(`@/views/Home.vue`);
      } else if (this.$route.name === "HomeId") {
        if (this.$store.state.onboardingState && this.validRoute) {
          return () => import(`@/views/Route.vue`);
        } else {
          return () => import(`@/views/Home.vue`);
        }
      }
      return null;
    },
  },
  methods: {
    setupCurrentRoute(routeId) {
      let parkData = new Park(this.$store.state.parkData);
      let cRoute = parkData.getRouteWithId(routeId);
      this.$store.commit("currentRoute", cRoute);
    },
  },
};
</script>
